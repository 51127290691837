import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import { getBigRandomNumber, getRandomNumbers } from "../services/homeApi";

export default function NumbersPage() {
  let [number1, setNumber1] = useState("Loading...");
  let [number2, setNumber2] = useState("Loading...");
  let [number3, setNumber3] = useState("Loading...");
  let [bigNumber, setBigNumber] = useState("Loading...");

  const { instance: msalClient } = useMsal();

  function fetchData1() {
    getRandomNumbers().then((result) => {
      if (result) {
        setNumber1(result);
      }
    });

    getRandomNumbers().then((result) => {
      if (result) {
        setNumber2(result);
      }
    });

    getRandomNumbers().then((result) => {
      if (result) {
        setNumber3(result);
      }
    });
  }

  function fetchData2() {
    msalClient.acquireTokenSilent(loginRequest).then((tokenResult) => {
      getBigRandomNumber(tokenResult.accessToken).then((result) => {
        if (result) {
          setBigNumber(result);
        }
      });
    });
  }

  function fetchData() {
    fetchData1();
    fetchData2();
  }

  useEffect(fetchData1, []);
  useEffect(fetchData2, [msalClient]);

  return (
    <>
      <div>{number1}</div>
      <div>{number2}</div>
      <div>{number3}</div>
      <div>{bigNumber}</div>
      <button onClick={fetchData}>Refresh</button>
    </>
  );
}
