const HOME_API_BASE_URL = process.env.REACT_APP_HOME_API_BASE_URL ?? "INVALID_ENVIRONMENT_USED_WHEN_BUILDING";

export async function getRandomNumbers() {
  return fetch(`${HOME_API_BASE_URL}/numbersJson`)
    .then((response) => response.text())
    .catch((error) => {
      console.log(`>>> ERROR: ${error}`);
    });
}

export async function getBigRandomNumber(accessToken: string) {
  return fetch(`${HOME_API_BASE_URL}/bigNumber`, { headers: { Authorization: `Bearer ${accessToken}` } })
    .then((response) => response.text())
    .catch((error) => {
      console.log(`>>> ERROR: ${error}`);
    });
}
