export default function LinksPage() {
  return (
    <>
      <a href="https://www.coursera.org/learn/neural-networks-deep-learning/home">Neural Networks and Deep Learning</a>
      <br />
      <a href="https://www.coursera.org/specializations/deep-learning">Deep Learning Specialization</a>
      <br />
      <br />
      <a href="https://docs.microsoft.com/en-us/learn/certifications/exams/az-500">AZ-500 Exam</a>
    </>
  );
}
