import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import { getBigRandomNumber } from "../services/homeApi";

export default function BigRandomNumber() {
  let [number, setNumber] = useState("Loading...");

  const { instance: msalClient } = useMsal();

  function fetchData() {
    msalClient.acquireTokenSilent(loginRequest).then((tokenResult) => {
      getBigRandomNumber(tokenResult.accessToken).then((result) => {
        if (result) {
          setNumber(result);
        }
      });
    });
  }

  useEffect(fetchData, [msalClient]);

  return <div>{number}</div>;
}
