import "./App.css";
import BigRandomNumber from "./components/BigRandomNumber";
import LinksPage from "./components/LinksPage";
import NumbersPage from "./components/NumbersPage";
import RandomNumber from "./components/RandomNumber";
import { useAccount, useMsal } from "@azure/msal-react";

export default function App() {
  // Set a default account for MSAL when the app loads.
  // This is safe because App is a child of MsalAuthenticationTemplate
  const { instance: msalClient, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  msalClient.setActiveAccount(account);

  return (
    <div className="App">
      <LinksPage />
      <br />
      <br />
      <NumbersPage />
      <br />
      <br />
      <RandomNumber />
      <RandomNumber />
      <RandomNumber />
      <BigRandomNumber />
    </div>
  );
}
