import { useEffect, useState } from "react";
import { getRandomNumbers } from "../services/homeApi";

export default function RandomNumber() {
  let [number, setNumber] = useState("Loading...");

  function fetchData() {
    getRandomNumbers().then((result) => {
      if (result) {
        setNumber(result);
      }
    });
  }

  useEffect(fetchData, []);

  return <div>{number}</div>;
}
